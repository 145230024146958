import { render, staticRenderFns } from "./account-list-by-id.vue?vue&type=template&id=c20ba614&scoped=true"
import script from "./account-list-by-id.vue?vue&type=script&lang=js"
export * from "./account-list-by-id.vue?vue&type=script&lang=js"
import style0 from "./account-list-by-id.vue?vue&type=style&index=0&id=c20ba614&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c20ba614",
  null
  
)

export default component.exports